import ProducList from "../../components/ProductList";
import "./Home.css"

function Home (){
    
    return(
        <>        
            <h2>Danh sach san pham</h2> 
           <ProducList/>
        </>
    )
}
export default Home; 