import { routes } from "../../routes";
import { useRoutes } from "react-router-dom";

function AllRouters (){

    const elements = useRoutes(routes);

    return(
        elements
    )
}
export default AllRouters;