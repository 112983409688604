import './App.css';
import AllRouters from './components/AllRouters';

function App() {
  return (
    <>
      <AllRouters/>
    </>
  );
}

export default App;
